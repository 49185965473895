import { type ReactNode } from 'react';

export default function FilterEmptyElements({
  pass,
  children,
}: {
  readonly pass: object | undefined;
  readonly children: ReactNode;
}) {
  if (!pass) {
    return <></>;
  }
  return <>{children}</>;
}
