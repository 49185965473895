import FoundationPlayer from 'components/UI/FoundationPlayer/FoundationPlayer';
import { Typography } from 'components/UI/Typography';
import type { VideoProps } from 'types/videoTypes';
import { formatMillisecondsToMinutes } from 'utils/time';
import { getVideoEncoding } from 'utils/videoPlayer';
import styles from './Video.module.scss';

export default function Video({ model }: { readonly model: VideoProps }) {
  const progressiveVideo = getVideoEncoding(model.fields.encoding);

  return (
    <div className={styles.root}>
      <FoundationPlayer video={model} />
      <div className={styles.info}>
        <Typography className={styles.duration} as="div" variant="4xs">
          {formatMillisecondsToMinutes(Number(progressiveVideo?.value.duration.value))} min
        </Typography>
        {model.fields.subtitle && (
          <Typography className={styles.subtitle} as="div" variant="5xs">
            {model.fields.subtitle}
          </Typography>
        )}
        {model.fields.title && (
          <Typography as="div" variant="m">
            {model.fields.title}
          </Typography>
        )}
      </div>
    </div>
  );
}
