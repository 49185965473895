import { Fragment } from 'react';
import StorylineDefaultType from 'components/partials/article/storyline-elements/StorylineDefaultType';
import RenderHTMLComment from 'components/partials/ReactComment';
import type { BasisStructure } from 'types/cms';
import type { StorylineModels } from 'types/storylines.d.ts';

type StorylineItemType = {
  readonly id: string;
  readonly type: string;
  readonly model: StorylineModels;
};

export interface WidgetArticleDetailDefaultProps extends BasisStructure {
  readonly items: ReadonlyArray<StorylineItemType>;
}

const WidgetArticleDetailDefault = ({ context }: { context: WidgetArticleDetailDefaultProps }) => (
  <>
    {context.items?.map((item) => (
      <Fragment key={item.id}>
        <RenderHTMLComment widgetType={item.type} />
        <StorylineDefaultType type={item.type} model={item.model} />
      </Fragment>
    ))}
  </>
);
export default WidgetArticleDetailDefault;
