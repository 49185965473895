import type { StoryLineListType, StoryLineParagraphModelType } from 'types/storylines';
import AnnotatedMarkup from '../AnnotatedMarkup/AnnotatedMarkup';
import styles from './BulletedList.module.scss';

export default function BulletedList({ model }: { readonly model: StoryLineListType }) {
  return (
    <ul className={styles.ul}>
      {model.related.map(
        (item) =>
          item.fields.paragraph && (
            <li className={styles.li} key={item.id}>
              <AnnotatedMarkup
                referenced={(item as StoryLineParagraphModelType).referenced}
                fields={(item as StoryLineParagraphModelType).fields.paragraph}
              />
            </li>
          )
      )}
    </ul>
  );
}
