import type { StoryLineLeadTextModelType } from 'types/storylines';
import AnnotatedMarkup from '../AnnotatedMarkup/AnnotatedMarkup';
import styles from './LeadText.module.scss';

export default function LeadText({ model }: { readonly model: StoryLineLeadTextModelType }) {
  return (
    <div className={styles.lead}>
      <AnnotatedMarkup referenced={model.referenced} fields={model.fields['lead-text']} />
    </div>
  );
}
