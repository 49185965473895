import { Typography } from 'components/UI/Typography';
import type {
  StoryLineLeadTextModelType,
  StoryLineListType,
  StoryLineParagraphModelType,
  StoryLinePictureType,
  StorylineEmbedType,
  StorylineHeadlineModelType,
  StorylineHtmlType,
  StorylineModels,
  StorylineSubheadlineModelType,
  StorylineSubtitleModelType,
  StorylineTickarooType,
} from 'types/storylines';
import type { VideoProps } from 'types/videoTypes';
import AnnotatedMarkup from './AnnotatedMarkup/AnnotatedMarkup';
import BulletedList from './BulletedList/BulletedList';
import Embed from './Embed/Embed';
import FilterEmptyElements from './FilterEmptyElements';
import Headline from './Headline/Headline';
import StorylineHtml from './Html/Html';
import LeadText from './LeadText/LeadText';
import NumberedList from './NumberedList/NumberedList';
import PersonalData from './PersonalData/PersonalData';
import Picture from './Picture/Picture';
import Subheadline from './Subheadline/Subheadline';
import Subtitle from './Subtitle/Subtitle';
import Tickaroo from './Tickaroo/Tickaroo';
import Video from './Video/Video';

export default function StorylineDefaultType({
  type,
  model,
}: {
  readonly type?: string;
  readonly model: StorylineModels;
}) {
  switch (type) {
    case 'storyline_headline':
      return (
        <FilterEmptyElements pass={model.fields.headline}>
          <Headline model={model as StorylineHeadlineModelType} />
        </FilterEmptyElements>
      );

    case 'storyline_subheadline':
      return (
        <FilterEmptyElements pass={model.fields.headline}>
          <Subheadline model={model as StorylineSubheadlineModelType} />
        </FilterEmptyElements>
      );

    case 'storyline_subtitle':
      return (
        <FilterEmptyElements pass={model.fields.subtitle}>
          <Subtitle model={model as StorylineSubtitleModelType} />
        </FilterEmptyElements>
      );

    case 'storyline_lead_text':
      return (
        <FilterEmptyElements pass={model.fields['lead-text']}>
          <LeadText model={model as StoryLineLeadTextModelType} />
        </FilterEmptyElements>
      );

    case 'storyline_paragraph':
      return (
        <FilterEmptyElements pass={model.fields.paragraph}>
          <AnnotatedMarkup
            referenced={(model as StoryLineParagraphModelType).referenced}
            fields={(model as StoryLineParagraphModelType).fields.paragraph}
          />
        </FilterEmptyElements>
      );

    case 'storyline_list_bulleted':
      return <BulletedList model={model as StoryLineListType} />;

    case 'storyline_list_numbered':
      return <NumberedList model={model as StoryLineListType} />;

    case 'storyline_embed':
      return (
        <FilterEmptyElements pass={(model as StorylineEmbedType).fields.uri}>
          <Embed model={model as StorylineEmbedType} />
        </FilterEmptyElements>
      );

    case 'picture':
      return <Picture model={model as StoryLinePictureType} />;

    case 'video':
      return <Video model={model as VideoProps} />;

    case 'html':
      return <StorylineHtml model={model as StorylineHtmlType} />;

    case 'tickaroo':
      return <Tickaroo model={model as StorylineTickarooType} />;

    case 'storyline_personal_data':
      return <PersonalData model={model} />;

    case 'storyline_image':
    case 'storyline_video':
    case 'storyline_html':
    case 'storyline_tickaroo':
    case 'storyline_affiliate':
    case 'storyline_video_playlist':
    case 'storyline_livestream':
      return <></>;

    default:
      return (
        <Typography as="h2" variant="s">
          STORYLINE &quot;{type}&quot; IS NOT YET IMPLEMENTED
        </Typography>
      );
  }
}
