import { Typography } from 'components/UI/Typography';
import type { StorylineSubheadlineModelType } from 'types/storylines';
import styles from './Subheadline.module.scss';

export default function Subheadline({ model }: { readonly model: StorylineSubheadlineModelType }) {
  return (
    <Typography className={styles.headline} as="h2" variant="m">
      {model.fields.headline?.value}
    </Typography>
  );
}
