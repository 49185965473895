import type { StorylineHtmlType } from 'types/storylines';
import { sanitizeHtml } from 'utils/string';
import styles from './Html.module.scss';

const StorylineHtml = ({ model }: { model: StorylineHtmlType }) => (
  <div
    className={styles.root}
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(model.fields.code),
    }}
  />
);

export default StorylineHtml;
