import type { FieldsStorylineLinkRelatedType, FieldstorylineNoteRelatedType, annotationsType } from 'types/storylines';
import { getReferrer, getRelProperty } from 'utils/getRelProperty';

type TagName =
  | 'bold'
  | 'strong'
  | 'italic'
  | 'underline'
  | 'strike'
  | 'subscript'
  | 'superscript'
  | 'inline_link'
  | 'note'
  | 'internal_link';

type StartAnnotationProps = {
  readonly pos?: number;
  readonly name: TagName;
  readonly value?: string;
  readonly target?: string;
  readonly noFollow?: boolean;
  readonly sponsored?: boolean;
  readonly title?: string;
};

type EndAnnotationProps = {
  readonly pos?: number;
  readonly name: TagName;
};

const getTag = (tagName: TagName) => {
  switch (tagName) {
    case 'bold':
    case 'strong':
      return 'strong';
    case 'inline_link':
    case 'internal_link':
      return 'a';
    case 'italic':
      return 'em';
    case 'note':
      return 'mark';
    case 'subscript':
      return 'sub';
    case 'superscript':
      return 'sup';
    case 'underline':
      return 'u';

    default:
      return tagName;
  }
};

function getTitleAttribute(title: string) {
  return title ? ` title="${title}"` : '';
}

const annotationConvert = (fields: annotationsType) => {
  if (fields.annotations?.length === 0) {
    return fields.value;
  }

  let annotated = '';

  const startAnnotations: Array<StartAnnotationProps> = [];
  let endAnnotations: Array<EndAnnotationProps> = [];

  fields.annotations.forEach((a) => {
    const linkRelatedTypeValue = a.value as FieldsStorylineLinkRelatedType;
    switch (a.name) {
      case 'inline_link':
      case 'internal_link':
        startAnnotations.push({
          pos: a.index,
          name: a.name,
          value: a.name === 'inline_link' ? linkRelatedTypeValue.uri?.value : linkRelatedTypeValue.relation?.value,
          target: linkRelatedTypeValue.newWindow?.value ? '_blank' : '_self',
          noFollow: linkRelatedTypeValue.noFollow?.value === 'true',
          sponsored: linkRelatedTypeValue.sponsored?.value === 'true',
        });
        break;
      case 'note':
        startAnnotations.push({
          pos: a.index,
          name: a.name,
          title: (a.value as FieldstorylineNoteRelatedType)?.text?.value,
        });
        break;
      default:
        startAnnotations.push({ pos: a.index, name: a.name });
        break;
    }

    endAnnotations.push({ pos: a.index + a.length, name: a.name });
  });

  endAnnotations = endAnnotations.reverse();

  for (let i = 0; i <= fields.value.length; i++) {
    endAnnotations
      .filter((a) => a.pos === i)
      .forEach((annotation) => {
        annotated = annotated + `</${getTag(annotation.name)}>`;
      });
    startAnnotations
      .filter((a) => a.pos === i)
      .forEach((annotation) => {
        switch (annotation.name) {
          case 'inline_link':
          case 'internal_link':
            {
              const relProperty = getRelProperty(
                annotation.noFollow,
                annotation.target,
                annotation.sponsored,
                getReferrer(annotation.value)
              );
              annotated += `<${getTag(annotation.name)}${relProperty ? ` rel="${relProperty}"` : ''} href="${
                annotation.value
              }" target="${annotation.target}">`;
            }
            break;
          default:
            annotated += `<${getTag(annotation.name)}${getTitleAttribute(annotation.title ?? '')}>`;
        }
      });

    annotated += fields.value.charAt(i);
  }
  return annotated;
};

export default annotationConvert;
