import { Typography } from 'components/UI/Typography';
import type { StorylineHeadlineModelType } from 'types/storylines';
import styles from './Headline.module.scss';

export default function Headline({ model }: { readonly model: StorylineHeadlineModelType }) {
  return (
    <Typography className={styles.headline} as="h2" variant="2xl">
      {model.fields.headline?.value}
    </Typography>
  );
}
