import parse from 'html-react-parser';
import { Typography } from 'components/UI/Typography';
import type {
  FieldsStorylineLinkRelatedType,
  FieldstorylineNoteRelatedType,
  StorylineLinkRelatedType,
  StorylineNoteRelatedType,
  annotationsType,
} from 'types/storylines';
import { toKebabCase } from 'utils/string';
import styles from './AnnotatedMarkup.module.scss';
import annotationConvert from './annotationConvert';

export type AnnotatedMarkupProps = {
  readonly fields: annotationsType;
  readonly referenced?: {
    [key: string]: StorylineLinkRelatedType | StorylineNoteRelatedType;
  };
};

export default function AnnotatedMarkup({ fields, referenced }: AnnotatedMarkupProps) {
  const deepCopyFields = JSON.parse(JSON.stringify(fields));

  if (referenced) {
    deepCopyFields.annotations.map(
      (item: { value: string | FieldsStorylineLinkRelatedType | FieldstorylineNoteRelatedType }) => {
        const referencedItemValue = referenced[item.value as string | number];

        if (referencedItemValue) {
          item.value = referencedItemValue.fields;
          return item.value;
        }
      }
    );
  }

  const annotated = annotationConvert(deepCopyFields);
  const splitToLineBreak = annotated.split(/\n\s*\n/);

  return splitToLineBreak.map((item, index) => {
    const id = toKebabCase(item) + index;
    return (
      <Typography key={id} className={styles.paragraph} as="p">
        {parse(item.trim().replace(/\n/g, '<br />'))}
      </Typography>
    );
  });
}
