import type { StorylinePersonalDataModelType } from 'types/storylines';
import styles from './PersonalData.module.scss';

const PersonalData = ({ model }: { readonly model: StorylinePersonalDataModelType }) => {
  const birthDate =
    model.fields['birth-date'] &&
    new Date(model.fields['birth-date'].value).toLocaleDateString('de-DE', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });

  return (
    <div className={styles.box}>
      {model.fields.name && (
        <div className={styles.entry}>
          <div className={styles.label}>Name:</div>
          <strong className={styles.value}>{model.fields.name.value}</strong>
        </div>
      )}
      {model.fields.job && (
        <div className={styles.entry}>
          <div className={styles.label}>Beruf:</div>
          <strong className={styles.value}>{model.fields.job.value}</strong>
        </div>
      )}
      {model.fields['birth-date'] && (
        <div className={styles.entry}>
          <div className={styles.label}>Geboren am:</div>
          <strong className={styles.value}>{birthDate}</strong>
        </div>
      )}
      {model.fields['birth-place'] && (
        <div className={styles.entry}>
          <div className={styles.label}>Geburtsort:</div>
          <strong className={styles.value}>{model.fields['birth-place'].value}</strong>
        </div>
      )}
      {model.fields['date-of-death'] && (
        <div className={styles.entry}>
          <div className={styles.label}>Todestag:</div>
          <strong className={styles.value}>{model.fields['date-of-death'].value}</strong>
        </div>
      )}
      {model.fields['body-height'] && (
        <div className={styles.entry}>
          <div className={styles.label}>Größe:</div>
          <strong className={styles.value}>{model.fields['body-height'].value} cm</strong>
        </div>
      )}
    </div>
  );
};

export default PersonalData;
