import type { StoryLineListType, StoryLineParagraphModelType } from 'types/storylines';
import AnnotatedMarkup from '../AnnotatedMarkup/AnnotatedMarkup';
import styles from './NumberedList.module.scss';

export default function NumberedList({ model }: { readonly model: StoryLineListType }) {
  return (
    <ol className={styles.ol}>
      {model.related.map(
        (item) =>
          item.fields.paragraph && (
            <li key={item.id} className={styles.li}>
              <AnnotatedMarkup
                referenced={(item as StoryLineParagraphModelType).referenced}
                fields={(item as StoryLineParagraphModelType).fields.paragraph}
              />
            </li>
          )
      )}
    </ol>
  );
}
