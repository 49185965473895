import { Typography } from 'components/UI/Typography';
import type { StorylineSubtitleModelType } from 'types/storylines';
import styles from './Subtitle.module.scss';

export default function Subtitle({ model }: { readonly model: StorylineSubtitleModelType }) {
  return (
    <Typography className={styles.subtitle} as="h2" variant="xs">
      {model.fields.subtitle?.value}
    </Typography>
  );
}
